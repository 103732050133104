import React from 'react';
import { LayoutGrid, Calendar, MessageSquare, Users, Briefcase, Menu } from 'lucide-react';

export default function Sidebar({ isOpen, toggleMenu, setCurrentView }) {
  return (
    <div className={`bg-gray-800 text-white transition-all duration-300 ${isOpen ? 'w-64' : 'w-16'}`}>
      <div className="p-4">
        <button onClick={toggleMenu} className="w-full flex items-center text-white">
          <Menu className="h-5 w-5 mr-2" />
          {isOpen && <span>Amazing Charts</span>}
        </button>
      </div>
      <nav className="mt-4">
        {[
          { icon: <LayoutGrid className="h-5 w-5 mr-2" />, label: 'Dashboard' },
          { icon: <Calendar className="h-5 w-5 mr-2" />, label: 'Schedule' },
          { icon: <MessageSquare className="h-5 w-5 mr-2" />, label: 'Messages' },
          { icon: <Users className="h-5 w-5 mr-2" />, label: 'Patients' },
          { icon: <Briefcase className="h-5 w-5 mr-2" />, label: 'Practice Management' },
        ].map((item, index) => (
          <button 
            key={index} 
            className="w-full flex items-center text-white p-2 hover:bg-gray-700"
            onClick={() => setCurrentView(item.label)}
          >
            {item.icon}
            {isOpen && <span>{item.label}</span>}
          </button>
        ))}
      </nav>
    </div>
  );
}