import React from 'react';

export default function Schedule({ appointments }) {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Today's Schedule</h1>
      <table className="w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2 text-left">Time</th>
            <th className="p-2 text-left">Patient</th>
            <th className="p-2 text-left">Age</th>
            <th className="p-2 text-left">Reason</th>
            <th className="p-2 text-left">Notes</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id} className="border-b">
              <td className="p-2">{appointment.time}</td>
              <td className="p-2 flex items-center">
                <img src={appointment.image} alt={`${appointment.firstName} ${appointment.lastName}`} className="w-8 h-8 rounded-full mr-2" />
                {appointment.lastName}, {appointment.firstName}
              </td>
              <td className="p-2">{appointment.age}</td>
              <td className="p-2">
                <span className={`px-2 py-1 rounded-full text-xs ${appointment.reason.includes('Sick Visit') ? 'bg-red-200 text-red-800' : 'bg-blue-200 text-blue-800'}`}>
                  {appointment.reason}
                </span>
              </td>
              <td className="p-2">{appointment.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}