import React from 'react';

export default function Patients({ patients }) {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Patients</h1>
      <table className="w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2 text-left">Name</th>
            <th className="p-2 text-left">Date of Birth</th>
            <th className="p-2 text-left">Gender</th>
            <th className="p-2 text-left">Phone</th>
            <th className="p-2 text-left">Email</th>
          </tr>
        </thead>
        <tbody>
          {patients.map((patient) => (
            <tr key={patient.id} className="border-b">
              <td className="p-2">
                <div className="flex items-center">
                  <img src={patient.image} alt={`${patient.firstName} ${patient.lastName}`} className="w-8 h-8 rounded-full mr-2" />
                  {patient.lastName}, {patient.firstName}
                </div>
              </td>
              <td className="p-2">{patient.dateOfBirth}</td>
              <td className="p-2">{patient.gender}</td>
              <td className="p-2">{patient.phoneNumber}</td>
              <td className="p-2">{patient.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}