import React, { useState } from 'react'
import { UserPlus, MessageSquare, Microscope, FileText, ClipboardList, ChevronLeft, ChevronRight, X } from 'lucide-react'

export default function Dashboard() {
  const currentDate = new Date()
  const [selectedDate, setSelectedDate] = useState(currentDate)
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [selectedPatient, setSelectedPatient] = useState(null)
  
  const appointmentsByDate = {
    [currentDate.toDateString()]: [
      { time: '9:00 AM', patient: 'Sarah Johnson', reason: 'Annual Check-up', age: 35, lastVisit: '3 months ago', medications: ['Lisinopril', 'Metformin'] },
      { time: '10:30 AM', patient: 'Michael Chen', reason: 'Follow-up', age: 28, lastVisit: '2 weeks ago', medications: ['Albuterol'] },
      { time: '11:45 AM', patient: 'Emily Davis', reason: 'Vaccination', age: 4, lastVisit: '6 months ago', medications: [] },
      { time: '2:00 PM', patient: 'Robert Wilson', reason: 'Consultation', age: 52, lastVisit: '1 month ago', medications: ['Atorvastatin', 'Aspirin'] },
    ],
    [new Date(currentYear, currentMonth, 15).toDateString()]: [
      { time: '8:30 AM', patient: 'John Smith', reason: 'Physical Exam', age: 45, lastVisit: '1 year ago', medications: ['Lisinopril'] },
      { time: '10:00 AM', patient: 'Emma Brown', reason: 'Prenatal Check-up', age: 29, lastVisit: '1 month ago', medications: ['Prenatal vitamins'] },
      { time: '1:30 PM', patient: 'David Lee', reason: 'Blood Pressure Check', age: 60, lastVisit: '3 months ago', medications: ['Amlodipine', 'Metoprolol'] },
    ],
    [new Date(currentYear, currentMonth, 22).toDateString()]: [
      { time: '9:15 AM', patient: 'Oliver Green', reason: 'Diabetes Management', age: 55, lastVisit: '3 months ago', medications: ['Metformin', 'Glipizide'] },
      { time: '11:00 AM', patient: 'Ava Martinez', reason: 'Pediatric Check-up', age: 7, lastVisit: '1 year ago', medications: [] },
      { time: '2:30 PM', patient: 'Ethan Taylor', reason: 'Orthopedic Follow-up', age: 40, lastVisit: '1 month ago', medications: ['Naproxen'] },
    ],
  }

  const getAppointmentsForDate = (date) => {
    return appointmentsByDate[date.toDateString()] || []
  }

  const inboxMessages = [
    { sender: 'Dr. Amanda Lee', subject: 'Patient Referral', urgent: true },
    { sender: 'John Doe', subject: 'Medication Question', urgent: false },
    { sender: 'Nurse Mark', subject: 'Lab Results', urgent: true },
    { sender: 'Jane Smith', subject: 'Appointment Request', urgent: false },
  ]

  const labsToReview = [
    { patient: 'George Brown', test: 'CBC', date: '2023-09-15' },
    { patient: 'Emma White', test: 'Lipid Panel', date: '2023-09-16' },
    { patient: 'Oliver Green', test: 'Thyroid Function', date: '2023-09-17' },
  ]

  const tasks = [
    { task: 'Update patient care plans', due: 'Today' },
    { task: 'Complete CME course', due: 'Tomorrow' },
    { task: 'Team meeting', due: 'Sep 20' },
    { task: 'Review new treatment guidelines', due: 'Sep 22' },
  ]

  const openCharts = [
    { patient: 'Alice Johnson', reason: 'Chronic Pain Management', lastUpdated: '1 hour ago' },
    { patient: 'Bob Smith', reason: 'Diabetes Follow-up', lastUpdated: '3 hours ago' },
    { patient: 'Carol Davis', reason: 'Anxiety Treatment', lastUpdated: 'Yesterday' },
  ]

  const deniedClaims = [
    { patient: 'David Wilson', date: '2023-09-10', amount: '$450', reason: 'Non-covered service' },
    { patient: 'Eva Brown', date: '2023-09-12', amount: '$275', reason: 'Missing information' },
    { patient: 'Frank Taylor', date: '2023-09-14', amount: '$680', reason: 'Out-of-network provider' },
  ]

  const generateCalendarDays = () => {
    const firstDay = new Date(currentYear, currentMonth, 1).getDay()
    const lastDate = new Date(currentYear, currentMonth + 1, 0).getDate()
    
    const days = []
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="text-center p-1"></div>)
    }
    for (let i = 1; i <= lastDate; i++) {
      const date = new Date(currentYear, currentMonth, i)
      const hasAppointments = !!appointmentsByDate[date.toDateString()]
      days.push(
        <button 
          key={`day-${i}`} 
          className={`text-center p-1 text-xs hover:bg-blue-100 rounded ${
            date.toDateString() === selectedDate.toDateString() ? 'bg-blue-500 text-white' : ''
          } ${hasAppointments ? 'font-bold' : ''}`}
          onClick={() => setSelectedDate(date)}
        >
          {i}
        </button>
      )
    }
    return days
  }

  const changeMonth = (increment) => {
    setCurrentMonth(prevMonth => {
      let newMonth = prevMonth + increment
      let newYear = currentYear

      if (newMonth > 11) {
        newMonth = 0
        newYear++
      } else if (newMonth < 0) {
        newMonth = 11
        newYear--
      }

      setCurrentYear(newYear)
      return newMonth
    })
  }

  const handlePatientClick = (patient) => {
    setSelectedPatient(patient)
  }

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <header className="mb-8">
        <h1 className="text-2xl font-bold">Welcome, Dr. Bertman</h1>
        <p className="text-gray-600">Today is {currentDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Schedule</h2>
          <div className="flex flex-col">
            <div className="mb-2">
              <div className="flex justify-between items-center mb-1">
                <button onClick={() => changeMonth(-1)} className="p-1"><ChevronLeft size={16} /></button>
                <span className="text-sm font-semibold">
                  {new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long', year: 'numeric' })}
                </span>
                <button onClick={() => changeMonth(1)} className="p-1"><ChevronRight size={16} /></button>
              </div>
              <div className="grid grid-cols-7 gap-1">
                {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
                  <div key={day} className="text-center text-xs font-semibold">{day}</div>
                ))}
                {generateCalendarDays()}
              </div>
            </div>
            <div>
              <h3 className="font-semibold text-sm mb-1">
                Appointments for {selectedDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
              </h3>
              <div className="max-h-40 overflow-y-auto">
                {getAppointmentsForDate(selectedDate).map((appointment, index) => (
                  <div 
                    key={index} 
                    className="text-xs mb-1 p-1 hover:bg-gray-100 rounded cursor-pointer"
                    onClick={() => handlePatientClick(appointment)}
                  >
                    <div className="font-semibold">{appointment.time} - {appointment.patient}</div>
                    <div className="text-gray-600">{appointment.reason}</div>
                  </div>
                ))}
                {getAppointmentsForDate(selectedDate).length === 0 && (
                  <p className="text-xs text-gray-500">No appointments scheduled for this day.</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Inbox</h2>
          {inboxMessages.map((message, index) => (
            <div key={index} className="mb-2 text-sm">
              <p className="font-semibold">{message.sender}</p>
              <p className="text-xs">{message.subject}</p>
              {message.urgent && <span className="text-xs text-red-500">Urgent</span>}
            </div>
          ))}
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Labs to Review</h2>
          {labsToReview.map((lab, index) => (
            <div key={index} className="mb-2 text-sm">
              <p className="font-semibold">{lab.patient}</p>
              <p className="text-xs">{lab.test} - {lab.date}</p>
            </div>
          ))}
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Tasks</h2>
          {tasks.map((task, index) => (
            <div key={index} className="flex justify-between items-center mb-2 text-sm">
              <p>{task.task}</p>
              <span className="text-xs text-gray-600">{task.due}</span>
            </div>
          ))}
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Open Charts</h2>
          {openCharts.map((chart, index) => (
            <div key={index} className="mb-2 text-sm">
              <p className="font-semibold">{chart.patient}</p>
              <p className="text-xs">{chart.reason}</p>
              <p className="text-xs text-gray-600">Updated: {chart.lastUpdated}</p>
            </div>
          ))}
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Denied Claims</h2>
          {deniedClaims.map((claim, index) => (
            <div key={index} className="mb-2 text-sm">
              <p className="font-semibold">{claim.patient}</p>
              <p className="text-xs">{claim.date} - {claim.amount}</p>
              <p className="text-xs text-red-500">{claim.reason}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white p-4 rounded shadow">
        <h2 className="text-lg font-semibold mb-4">Quick Actions</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {[
            { icon: UserPlus, label: 'New Patient' },
            { icon: MessageSquare, label: 'Send Message' },
            { icon: Microscope, label: 'Order Lab Test' },
            { icon: FileText, label: 'Create Prescription' },
            { icon: ClipboardList, label: 'View Reports' },
          ].map((action, index) => (
            <button key={index} className="flex flex-col items-center justify-center p-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
              <action.icon className="mb-1 h-5 w-5" />
              <span className="text-xs text-center">{action.label}</span>
            </button>
          ))}
        </div>
      </div>

      {selectedPatient && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-xl font-bold">{selectedPatient.patient}</h3>
              <button onClick={() => setSelectedPatient(null)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <div className="space-y-2">
              <p><span className="font-semibold">Age:</span> {selectedPatient.age}</p>
              <p><span className="font-semibold">Reason for Visit:</span> {selectedPatient.reason}</p>
              <p><span className="font-semibold">Last Visit:</span> {selectedPatient.lastVisit}</p>
              <div>
                <span className="font-semibold">Medications:</span>
                {selectedPatient.medications.length > 0 ? (
                  <ul className="list-disc list-inside">
                    {selectedPatient.medications.map((med, index) => (
                      <li key={index}>{med}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No current medications</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}