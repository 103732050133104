import React, { useState } from 'react'

// Helper function to format numbers with commas
const formatNumber = (num) => {
  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function PracticeManagement() {
  const [showRevenueBreakdown, setShowRevenueBreakdown] = useState(false)
  const [showClaimsBreakdown, setShowClaimsBreakdown] = useState(false)

  const practiceData = {
    name: "Hope Valley Family Medicine",
    totalRevenue: 576432.87,
    outstandingClaims: 120567.43,
    averageClaimProcessingTime: 42,
    totalPatients: 3302,
    activePatients: 2897,
    appointmentsThisMonth: 483,
    growthRate: 6.53,
  }

  const doctorData = [
    {
      name: "Dr. J. Bertman, MD",
      patientsSeenThisMonth: 322,
      revenueGenerated: 384289.56,
      claimsDenialRate: 3.02,
      totalPatients: 2104,
    },
    {
      name: "Dr. S. Jones, DO",
      patientsSeenThisMonth: 161,
      revenueGenerated: 192143.31,
      claimsDenialRate: 2.48,
      totalPatients: 799,
    },
  ]

  const revenueBreakdownData = [
    { payor: "Blue Cross Blue Shield of Massachusetts", revenueReceived: 172345.67, claims: 1723, patients: 689, dollarPerPatient: 250.14, claimDenialRate: 2.8 },
    { payor: "Medicare", revenueReceived: 138765.43, claims: 2312, patients: 925, dollarPerPatient: 150.02, claimDenialRate: 1.5 },
    { payor: "MassHealth (Medicaid)", revenueReceived: 86543.21, claims: 1442, patients: 577, dollarPerPatient: 149.99, claimDenialRate: 3.2 },
    { payor: "Harvard Pilgrim Health Care", revenueReceived: 69876.54, claims: 698, patients: 279, dollarPerPatient: 250.45, claimDenialRate: 2.5 },
    { payor: "Tufts Health Plan", revenueReceived: 57654.32, claims: 576, patients: 230, dollarPerPatient: 250.67, claimDenialRate: 2.7 },
    { payor: "Self Pay", revenueReceived: 34567.89, claims: 345, patients: 138, dollarPerPatient: 250.49, claimDenialRate: 0.5 },
    { payor: "Other Insurance", revenueReceived: 16679.81, claims: 166, patients: 65, dollarPerPatient: 256.61, claimDenialRate: 3.0 },
  ]

  const outstandingClaimsData = [
    { payor: "Blue Cross Blue Shield of Massachusetts", amount: 45678.90, claims: 456, avgDaysOutstanding: 32 },
    { payor: "Medicare", amount: 34567.89, claims: 345, avgDaysOutstanding: 28 },
    { payor: "MassHealth (Medicaid)", amount: 23456.78, claims: 234, avgDaysOutstanding: 40 },
    { payor: "Harvard Pilgrim Health Care", amount: 12345.67, claims: 123, avgDaysOutstanding: 35 },
    { payor: "Tufts Health Plan", amount: 4321.09, claims: 43, avgDaysOutstanding: 30 },
    { payor: "Other Insurance", amount: 197.10, claims: 2, avgDaysOutstanding: 25 },
  ]

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Practice Management</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div 
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-50 transition-colors duration-200"
          onClick={() => setShowRevenueBreakdown(!showRevenueBreakdown)}
        >
          <h2 className="text-lg font-semibold">Total Revenue (YTD)</h2>
          <p className="text-2xl font-bold">${formatNumber(practiceData.totalRevenue)}</p>
          <p className="text-sm text-gray-600">+{practiceData.growthRate.toFixed(2)}% from last year</p>
          <p className="text-xs text-blue-600 mt-2">Click to {showRevenueBreakdown ? 'hide' : 'show'} breakdown</p>
        </div>
        
        <div 
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-50 transition-colors duration-200"
          onClick={() => setShowClaimsBreakdown(!showClaimsBreakdown)}
        >
          <h2 className="text-lg font-semibold">Outstanding Claims</h2>
          <p className="text-2xl font-bold">${formatNumber(practiceData.outstandingClaims)}</p>
          <p className="text-sm text-gray-600">{practiceData.averageClaimProcessingTime} Avg. days in A/R</p>
          <p className="text-xs text-blue-600 mt-2">Click to {showClaimsBreakdown ? 'hide' : 'show'} breakdown</p>
        </div>
        
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold">Active Patients</h2>
          <p className="text-2xl font-bold">{practiceData.activePatients.toLocaleString()}</p>
          <p className="text-sm text-gray-600">{practiceData.totalPatients.toLocaleString()} total patients</p>
        </div>
        
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold">Scheduled Visits</h2>
          <p className="text-2xl font-bold">{practiceData.appointmentsThisMonth.toLocaleString()}</p>
        </div>
      </div>

      {showRevenueBreakdown && (
        <div className="bg-white p-4 rounded shadow mb-8">
          <h2 className="text-lg font-semibold mb-4">Revenue Breakdown by Payor</h2>
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-left p-2">Payor</th>
                <th className="text-left p-2">Revenue Received</th>
                <th className="text-left p-2">Claims</th>
                <th className="text-left p-2">Patients</th>
                <th className="text-left p-2">$/Patient</th>
                <th className="text-left p-2">Claim Denial Rate</th>
              </tr>
            </thead>
            <tbody>
              {revenueBreakdownData.map((payor, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">{payor.payor}</td>
                  <td className="p-2">${formatNumber(payor.revenueReceived)}</td>
                  <td className="p-2">{payor.claims.toLocaleString()}</td>
                  <td className="p-2">{payor.patients.toLocaleString()}</td>
                  <td className="p-2">${formatNumber(payor.dollarPerPatient)}</td>
                  <td className="p-2">{payor.claimDenialRate.toFixed(1)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showClaimsBreakdown && (
        <div className="bg-white p-4 rounded shadow mb-8">
          <h2 className="text-lg font-semibold mb-4">Outstanding Claims Breakdown by Payor</h2>
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-left p-2">Payor</th>
                <th className="text-left p-2">Outstanding Amount</th>
                <th className="text-left p-2">Number of Claims</th>
                <th className="text-left p-2">Avg. Days Outstanding</th>
              </tr>
            </thead>
            <tbody>
              {outstandingClaimsData.map((payor, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">{payor.payor}</td>
                  <td className="p-2">${formatNumber(payor.amount)}</td>
                  <td className="p-2">{payor.claims.toLocaleString()}</td>
                  <td className="p-2">{payor.avgDaysOutstanding}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="bg-white p-4 rounded shadow mb-8">
        <h2 className="text-lg font-semibold mb-4">Doctor Performance</h2>
        <table className="w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-left p-2">Doctor</th>
              <th className="text-left p-2">Patients Seen (Month)</th>
              <th className="text-left p-2">Revenue Generated (YTD)</th>
              <th className="text-left p-2">Claim Denial Rate</th>
            </tr>
          </thead>
          <tbody>
            {doctorData.map((doctor, index) => (
              <tr key={index} className="border-b">
                <td className="p-2">{doctor.name}</td>
                <td className="p-2">{doctor.patientsSeenThisMonth.toLocaleString()}</td>
                <td className="p-2">${formatNumber(doctor.revenueGenerated)}</td>
                <td className="p-2">{doctor.claimsDenialRate.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}