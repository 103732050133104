import React, { useState } from 'react';
import { X } from 'lucide-react';

export default function Messages({ messages }) {
  const [viewType, setViewType] = useState('tile');

  const renderListView = () => (
    <div className="space-y-4">
      {messages.map((message) => (
        <div key={message.id} className={`bg-white p-4 rounded-lg shadow-sm flex items-center ${message.unread ? 'border-l-4 border-blue-500' : ''}`}>
          <div className="mr-4 flex-shrink-0">
            {message.icon}
          </div>
          <div className="flex-grow">
            <h3 className={`text-lg ${message.unread ? 'font-bold' : 'font-semibold'}`}>{message.subject}</h3>
            <p className="text-sm text-gray-600">
              {message.patient ? `Patient: ${message.patient}` : `From: ${message.from}`}
            </p>
            <p className="text-sm text-gray-600">{message.content}</p>
          </div>
          <div className="ml-4 text-sm text-gray-500">
            {new Date(message.date).toLocaleString()}
          </div>
        </div>
      ))}
    </div>
  );

  const renderTileView = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {messages.map((message) => (
        <div key={message.id} className="bg-white p-4 rounded-lg shadow relative">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              {message.icon}
              <span className="font-semibold ml-2">
                {message.type.charAt(0).toUpperCase() + message.type.slice(1)}
              </span>
            </div>
            {message.priority && (
              <span className={`px-2 py-1 rounded-full text-xs ${message.priority === 'high' ? 'bg-red-200 text-red-800' : 'bg-blue-200 text-blue-800'}`}>
                {message.priority.charAt(0).toUpperCase() + message.priority.slice(1)}
              </span>
            )}
          </div>
          <h3 className="text-lg font-semibold mb-1">{message.subject}</h3>
          <p className="text-sm text-gray-600 mb-2">
            {message.patient ? `Patient: ${message.patient}` : `From: ${message.from}`}
          </p>
          <p className="text-sm text-gray-600 mb-8">{message.content}</p>
          <button className="absolute bottom-2 right-2 text-red-500 hover:text-red-700">
            <X className="h-4 w-4" />
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">
          Messages <span className="text-lg font-normal text-gray-500">(Unread: {messages.filter(m => m.unread).length})</span>
        </h1>
        <select 
          className="p-2 border rounded"
          value={viewType}
          onChange={(e) => setViewType(e.target.value)}
        >
          <option value="tile">Tile View</option>
          <option value="list">List View</option>
        </select>
      </div>
      {viewType === 'list' ? renderListView() : renderTileView()}
    </div>
  );
}