import React, { useState, useEffect } from 'react';
import { X, Mic, FileText, FileOutput, Stethoscope } from 'lucide-react';

export default function AIAssistant({ isOpen, toggleChat }) {
  const [input, setInput] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [chatMessages, setChatMessages] = useState([
    { text: "Hello! How can I assist you today?", sender: 'ai' }
  ]);

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (isListening) {
      timer = setTimeout(() => {
        setShowNotice(true);
      }, 3000);
    } else {
      setShowNotice(false);
    }
    return () => clearTimeout(timer);
  }, [isListening]);

  const resetState = () => {
    setInput('');
    setIsListening(false);
    setShowNotice(false);
    setChatMessages([
      { text: "Hello! How can I assist you today?", sender: 'ai' }
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      setChatMessages([...chatMessages, { text: input, sender: 'user' }]);
      // Simulated AI response
      setTimeout(() => {
        setChatMessages(prev => [...prev, { text: "I'm an AI assistant. How can I help you with that?", sender: 'ai' }]);
      }, 1000);
      setInput('');
    }
  };

  const handleButtonClick = (action) => {
    setChatMessages([...chatMessages, { text: `Initiating ${action}...`, sender: 'ai' }]);
    // Here you would typically trigger the actual functionality
  };

  const handleMicClick = () => {
    setIsListening(!isListening);
    setShowNotice(false);
    // Here you would implement the voice input functionality
    console.log(isListening ? 'Stopped listening' : 'Started listening');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed right-0 top-0 h-full w-80 bg-white shadow-lg flex flex-col">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">AI Assistant</h2>
        <button onClick={toggleChat}><X className="h-5 w-5" /></button>
      </div>
      <div className="flex flex-wrap justify-center gap-2 p-4 border-b">
        <button 
          onClick={() => handleButtonClick('Summarize Content')}
          className="flex flex-col items-center p-2 bg-blue-500 rounded hover:bg-blue-600 text-white"
        >
          <FileText className="h-6 w-6 mb-1" />
          <span className="text-xs">Summarize Content</span>
        </button>
        <button 
          onClick={() => handleButtonClick('Generate Output')}
          className="flex flex-col items-center p-2 bg-blue-500 rounded hover:bg-blue-600 text-white"
        >
          <FileOutput className="h-6 w-6 mb-1" />
          <span className="text-xs">Generate Output</span>
        </button>
        <button 
          onClick={() => handleButtonClick('Curbside Virtual Clinician')}
          className="flex flex-col items-center p-2 bg-blue-500 rounded hover:bg-blue-600 text-white"
        >
          <Stethoscope className="h-6 w-6 mb-1" />
          <span className="text-xs">Curbside Virtual Clinician(s)</span>
        </button>
      </div>
      <div className="flex-1 p-4 overflow-auto">
        {chatMessages.map((msg, index) => (
          <div key={index} className={`mb-2 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded-lg ${msg.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
              {msg.text}
            </span>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="p-4 border-t flex relative">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type or click mic to speak..."
          className="flex-1 p-2 border rounded-l"
        />
        <button 
          type="button" 
          onClick={handleMicClick} 
          className={`text-white p-2 rounded-r transition-colors duration-300 ${isListening ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'}`}
        >
          <Mic className="h-5 w-5" />
        </button>
        {isListening && (
          <div className="absolute bottom-full left-0 right-0 bg-white p-4 shadow-lg rounded-t-lg border border-gray-200 flex flex-col items-center justify-center">
            <div className="flex items-center">
              <div className="animate-pulse mr-2 h-3 w-3 bg-red-500 rounded-full"></div>
              <span className="text-lg font-semibold">I'm listening...</span>
            </div>
            {showNotice && (
              <span className="text-sm text-gray-500 mt-2">(Not really. Not yet anyway...)</span>
            )}
          </div>
        )}
      </form>
    </div>
  );
}