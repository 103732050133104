import React, { useState } from 'react';
import { Search, Calendar, Settings, HelpCircle, Brain } from 'lucide-react';

const patients = [
  { id: 1, lastName: "Brown", firstName: "Alice", age: 35, gender: "F", lastEncounter: 1, outstandingOrders: 2, overdueCare: 1, image: "https://i.pravatar.cc/32?img=1" },
  { id: 2, lastName: "Baker", firstName: "Bob", age: 42, gender: "M", lastEncounter: 2, outstandingOrders: 0, overdueCare: 0, image: "https://i.pravatar.cc/32?img=3" },
  { id: 3, lastName: "Benson", firstName: "Carol", age: 28, gender: "F", lastEncounter: 0.5, outstandingOrders: 1, overdueCare: 0, image: "https://i.pravatar.cc/32?img=5" },
  { id: 4, lastName: "Blackwell", firstName: "David", age: 55, gender: "M", lastEncounter: 3, outstandingOrders: 3, overdueCare: 2, image: "https://i.pravatar.cc/32?img=7" },
  { id: 5, lastName: "Bradshaw", firstName: "Emma", age: 31, gender: "F", lastEncounter: 0.25, outstandingOrders: 0, overdueCare: 0, image: "https://i.pravatar.cc/32?img=9" },
];

export default function TopNavigation({ toggleChat, appointments }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSchedule, setShowSchedule] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const filteredPatients = patients.filter(patient => 
    patient.lastName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
    patient.firstName.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const formatLastEncounter = (months) => {
    if (months < 1) {
      return `${Math.round(months * 30)} days`;
    } else if (months < 12) {
      return `${Math.round(months)} month${months === 1 ? '' : 's'}`;
    } else {
      const years = Math.floor(months / 12);
      const remainingMonths = months % 12;
      return `${years} year${years === 1 ? '' : 's'}${remainingMonths > 0 ? ` ${Math.round(remainingMonths)} month${remainingMonths === 1 ? '' : 's'}` : ''}`;
    }
  };

  const handleHelpClick = () => {
    window.open('https://direct.lc.chat/7025761/2', '_blank', 'noopener,noreferrer');
  };

  return (
    <header className="bg-white shadow-sm p-4 flex justify-between items-center">
      <div className="flex items-center space-x-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-64 pl-10 pr-10 p-2 border rounded"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div className="relative">
          <button
            className="p-2 text-gray-600 hover:text-blue-600"
            onClick={() => setShowSchedule(!showSchedule)}
          >
            <Calendar className="h-5 w-5 mr-2 inline-block" />
            Schedule
          </button>
          {showSchedule && (
            <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-10 p-4">
              <h4 className="font-medium leading-none mb-2">Today's Schedule</h4>
              <p className="text-sm text-gray-600 mb-4">
                {new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
              </p>
              {appointments.map((appointment, index) => (
                <div key={index} className="flex items-center mb-2">
                  <div className="w-[60px] text-sm">{appointment.time}</div>
                  <div className="ml-4">
                    <p className="text-sm font-medium leading-none">{`${appointment.lastName}, ${appointment.firstName}`}</p>
                    <p className="text-sm text-gray-600">{appointment.reason}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="relative">
          <button
            className="p-2 text-gray-600 hover:text-blue-600"
            onClick={() => setShowSettings(!showSettings)}
          >
            <Settings className="h-5 w-5 mr-2 inline-block" />
            Settings
          </button>
          {showSettings && (
            <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg z-10">
              <div className="py-1">
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Notification Preferences</button>
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Security Settings</button>
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Customize Dashboard</button>
              </div>
            </div>
          )}
        </div>
        <button className="p-2 text-gray-600 hover:text-blue-600" onClick={handleHelpClick}>
          <HelpCircle className="h-5 w-5 mr-2 inline-block" />
          Help
        </button>
        <button 
          className="p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
          onClick={toggleChat}
        >
          <Brain className="h-5 w-5" />
        </button>
        <div className="relative">
          <button
            className="flex items-center space-x-2 p-2"
            onClick={() => setShowUserMenu(!showUserMenu)}
          >
            <img src="https://i.pravatar.cc/32?img=30" alt="Dr. Jane Smith" className="h-8 w-8 rounded-full" />
            <span className="text-sm font-medium text-gray-700">Dr. J. Smith</span>
          </button>
          {showUserMenu && (
            <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg z-10">
              <div className="py-1">
              <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile Settings</button>
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Account Settings</button>
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Preferences</button>
                <hr className="my-1 border-gray-200" />
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Sign Out</button>
              </div>
            </div>
          )}
        </div>
      </div>
      {searchTerm && (
        <div className="absolute left-0 w-full max-w-2xl mt-2 border rounded-md shadow-lg bg-white overflow-hidden z-10">
          <div className="bg-gray-100 p-2 grid grid-cols-12 gap-2 text-sm font-semibold">
            <div className="col-span-1"></div>
            <div className="col-span-3">Name</div>
            <div className="col-span-1 text-center">Age</div>
            <div className="col-span-1 text-center">Gender</div>
            <div className="col-span-2 text-center">Last Encounter</div>
            <div className="col-span-2 text-center">Outstanding Orders</div>
            <div className="col-span-2 text-center">Overdue Care</div>
          </div>
          <div className="max-h-96 overflow-y-auto">
            {filteredPatients.map(patient => (
              <div key={patient.id} className="p-2 hover:bg-gray-100 grid grid-cols-12 gap-2 items-center cursor-pointer">
                <div className="col-span-1">
                  <img src={patient.image} alt={`${patient.firstName} ${patient.lastName}`} className="w-8 h-8 rounded-full" />
                </div>
                <div className="col-span-3">{patient.lastName}, {patient.firstName}</div>
                <div className="col-span-1 text-center">{patient.age}</div>
                <div className="col-span-1 text-center">{patient.gender}</div>
                <div className="col-span-2 text-center">{formatLastEncounter(patient.lastEncounter)} ago</div>
                <div className="col-span-2 text-center">{patient.outstandingOrders}</div>
                <div className="col-span-2 text-center">{patient.overdueCare}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </header>
  );
}