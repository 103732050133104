import React, { useState } from 'react';
import Sidebar from './Sidebar';
import TopNavigation from './TopNavigation';
import Dashboard from './Dashboard';
import Schedule from './Schedule';
import Messages from './Messages';
import Patients from './Patients';
import AIAssistant from './AIAssistant';
import PracticeManagement from './PracticeManagement';

// Hardcoded demo data
const appointments = [
  { id: 1, time: "09:00", image: "https://i.pravatar.cc/32?img=53", lastName: "Smith", firstName: "John", age: 45, reason: "F/U (15m)", notes: "Blood pressure check", type: "first" },
  { id: 2, time: "09:30", image: "https://i.pravatar.cc/32?img=20", lastName: "Johnson", firstName: "Emily", age: 2, reason: "Well Child Check (30m)", notes: "2-year checkup and vaccinations" },
  { id: 3, time: "10:00", image: "https://i.pravatar.cc/32?img=65", lastName: "Brown", firstName: "Olivia", age: 67, reason: "Medicare Wellness Visit (40m)", notes: "Comprehensive health assessment" },
  { id: 4, time: "11:00", image: "https://i.pravatar.cc/32?img=33", lastName: "Miller", firstName: "Sophia", age: 35, reason: "Sick Visit (15m)", notes: "Sore Throat" },
];

const messages = [
  { id: 1, type: 'internal', priority: 'high', subject: 'Lunch Today?', content: 'The drug rep wants to bring in lunch, can we?', from: 'Jody (Front Desk)', date: '2024-09-30T10:30:00', unread: true },
  { id: 2, type: 'external', subject: 'ACO Meeting Cancelled', content: "Tonight's ACO meeting is cancelled. Will email with new date.", from: 'appointments@clinic.com', date: '2024-09-30T09:45:00', unread: true },
  { id: 3, type: 'system', subject: 'Offsite Backup Completed', content: 'Daily offsite backup has been successfully completed.', from: 'System', date: '2024-09-30T02:00:00', unread: false },
  { id: 4, type: 'lab', priority: 'new', subject: 'Quest Labs Imported', content: 'New lab results have been imported for review.', patient: 'Ashley, William', date: '2024-09-29T18:30:00', unread: true },
];

const patients = [
  { id: 1, lastName: "Doe", firstName: "Jane", dateOfBirth: "1985-03-15", gender: "Female", phoneNumber: "555-123-4567", email: "jane.doe@example.com", address: "123 Main St, Anytown, USA", image: "https://i.pravatar.cc/32?img=1" },
  { id: 2, lastName: "Smith", firstName: "John", dateOfBirth: "1978-07-22", gender: "Male", phoneNumber: "555-987-6543", email: "john.smith@example.com", address: "456 Elm St, Somewhere, USA", image: "https://i.pravatar.cc/32?img=2" },
  { id: 3, lastName: "Johnson", firstName: "Emily", dateOfBirth: "2022-01-10", gender: "Female", phoneNumber: "555-246-8135", email: "emily.johnson@example.com", address: "789 Oak St, Nowhere, USA", image: "https://i.pravatar.cc/32?img=3" },
  { id: 4, lastName: "Brown", firstName: "Olivia", dateOfBirth: "1957-11-30", gender: "Female", phoneNumber: "555-369-2580", email: "olivia.brown@example.com", address: "101 Pine St, Everywhere, USA", image: "https://i.pravatar.cc/32?img=4" },
];

export default function App() {
  const [currentView, setCurrentView] = useState('Dashboard');
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleChat = () => setIsChatOpen(!isChatOpen);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const renderCurrentView = () => {
    switch(currentView) {
      case 'Dashboard': return <Dashboard />;
      case 'Schedule': return <Schedule appointments={appointments} />;
      case 'Messages': return <Messages messages={messages} />;
      case 'Patients': return <Patients patients={patients} />;
      case 'Practice Management': return <PracticeManagement />;
      default: return <Dashboard />;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar isOpen={isMenuOpen} toggleMenu={toggleMenu} setCurrentView={setCurrentView} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <TopNavigation toggleChat={toggleChat} appointments={appointments} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          {renderCurrentView()}
        </main>
      </div>
      <AIAssistant isOpen={isChatOpen} toggleChat={toggleChat} />
    </div>
  );
}